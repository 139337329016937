<template>
  <auth-container>
    <v-col slot="right" justify="center">
      <v-row justify="center">
        <v-img
          max-width="147"
          max-height="67"
          src="../../assets/Buro.svg"
        ></v-img>
      </v-row>
      <v-row justify="center">
        <v-card outlined class="mt-4 px-6 pb-4" width="340">
          <v-form @submit.prevent="signUp" class="mt-4 pa-4">
            <label class="body-1 ">Create New Account</label>
            <v-text-field
              v-model="name"
              name="name"
              @blur="$v.name.$touch"
              tabindex="1"
              required
              :error-messages="nameErrorMessages"
              placeholder="Full Name"
            ></v-text-field>
            <v-text-field
              class="ma-0 pa-0"
              v-model="email"
              name="email"
              tabindex="2"
              @blur="$v.email.$touch"
              required
              :readonly="readonlyEmail"
              :error-messages="emailErrorMessages"
              placeholder="Email Address"
            ></v-text-field>
            <v-text-field
              class="ma-0 pa-0"
              tabindex="3"
              v-model="password"
              name="pass"
              type="password"
              placeholder="Password"
              @blur="$v.password.$touch"
              required
              :error-messages="passwordErrorMessages"
            ></v-text-field>

            <v-text-field
              class="ma-0 pa-0"
              tabindex="4"
              v-model="confirmPassword"
              name="pass"
              type="password"
              placeholder="Confirm Password"
              required
            ></v-text-field>

            <transition name="slide-fade">
              <v-alert v-if="hasError" type="error" class="caption pa-1 mt-4">{{
                this.errorMessage
              }}</v-alert>
            </transition>

            <bz-submit-button
              :loading="loading"
              :disabled="this.$v.$invalid"
              title="Signup"
              icon="mdi-lock"
              class="full-width mt-4"
              type="Submit"
            >
              <v-divider vertical color="white" class="ml-2" />
            </bz-submit-button>
            <div class="mt-4 d-flex flex-column align-start">
              <auth-link-button
                title="Already a member? Login"
                :to="{ name: 'log-in' }"
              />
            </div>
          </v-form>
        </v-card>
      </v-row>
    </v-col>
  </auth-container>
</template>

<script>
import AuthContainer from "../components/AuthContainer";
import BzSubmitButton from "@shared/components/BzSubmitButton";
import AuthLinkButton from "../components/AuthLinkButton";

import { mapActions } from "vuex";
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapFields } from "vuex-map-fields";
import { helpers } from "vuelidate/lib/validators";
const isFullName = helpers.regex(
  "isFullName",
  /^([\w]{2,})+\s+([\w\s]{2,})+$/i
);

export default {
  name: "SignUpCard",
  components: {
    AuthContainer,
    BzSubmitButton,
    AuthLinkButton,
  },
  data() {
    return {
      email: "",
      name: "",
      readonlyEmail: false,
      password: "",
      errorMessage: "",
      loading: false,
      showPassword: false,
      confirmPassword: "",
      code: "",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(5),
    },
    name: {
      required,
      isFullName,
    },
  },
  computed: {
    ...mapFields("beezIn", {
      beezinCode: "beezinCode",
    }),
    emailErrorMessages() {
      const errors = [];
      if (!this.$v.email.$dirty) return [];
      !this.$v.email.required && errors.push("Email is required.");
      !this.$v.email.email && errors.push("Please enter a valid email address");
      return errors;
    },
    nameErrorMessages() {
      const errors = [];
      if (!this.$v.name.$dirty) return [];
      !this.$v.name.required && errors.push("Full name is required.");
      !this.$v.name.isFullName && errors.push("Please enter your full name");
      return errors;
    },
    passwordErrorMessages() {
      const errors = [];
      if (!this.$v.password.$dirty) return [];
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push("Password must be at least 5 characters long");
      return errors;
    },
    passwordsMatch() {
      return this.password === this.confirmPassword;
    },
    hasError() {
      return this.errorMessage && this.errorMessage.length;
    },
  },
  methods: {
    ...mapActions("auth", {
      signUpUser: "signUpUser",
    }),
    async signUp() {
      if (!this.passwordsMatch) {
        this.errorMessage = "Passwords DO NOT match";
        this.password = "";
        this.confirmPassword = "";
        setTimeout(() => {
          this.errorMessage = "";
        }, 2000);
        return;
      }
      const names = this.name.split(" ");
      // signup user
      try {
        this.loading = true;
        if (this.code == "") {
          await this.signUpUser({
            email: this.email,
            password: this.password,
            firstName: names[0],
            lastName: names[1] !== undefined ? names[1] : "",
          });
        } else {
          await this.signUpUser({
            email: this.email,
            password: this.password,
            firstName: names[0],
            lastName: names[1] !== undefined ? names[1] : "",
            code: this.code,
          });
        }

        this.$router.replace({
          name: "verify",
          query: { email: this.email },
        });
      } catch (error) {
        this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 3000);
      } finally {
        this.loading = false;
      }
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
  created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      this.readonlyEmail = true;
    }

    if (this.beezinCode != null) {
      this.code = this.beezinCode;
    }
  },
};
</script>
